import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { EstatusFolioPage } from '../pages/EstatusFolioPage'
import { HomePage } from '../pages/HomePage'
import  GarantIRPage  from '../pages/GarantIRPage'
import NavBar from '../components/layout/NavBar'

const theme = createTheme();

export const ProtectedRoutes = () => {


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <NavBar/>

      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        <Box sx={{ pt: 4 }}>
          <Routes>
            <Route index element={<HomePage/>} />
            <Route path="/home" element={<HomePage/>} />
            <Route path="/estatus" element={<EstatusFolioPage/> } />
            <Route path="/garantir" element={<GarantIRPage/> } />
            <Route path="/*" element={<HomePage/> } />
          </Routes>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
