

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CottageIcon from '@mui/icons-material/Cottage';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// CircularProgress from material ui 
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector } from 'react-redux';
import { useAuthStore } from '../hooks/useAuthStore';
// import { getInmueble} from '../store/slices/inmueble';

import { useFormik } from 'formik';
import * as yup from 'yup';

const regexFolio = /^[a-zA-Z]{3}[a-zA-Z0-9]{5,7}$/;

const validationSchema = yup.object({
  folio: yup
    .string('Ingresa el folio')
    .required('Folio is required')
    .matches(regexFolio, 'Folio no valido, debe tener el formato ABC12345'),
});



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ConsultaPage() {

  // const { onChecking, onLogin, onLogout, clearErrorMessage} = useSelector(state => state.auth);
  const { status} = useSelector(state => state.auth);
  // const { startLogin, errorMessage } = useAuthStore();
  const { startLogin } = useAuthStore();

  const formik = useFormik({
    initialValues:{
      folio: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      // dispatch(getInmueble(values.folio));
      startLogin(values.folio);

    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/home.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: ' 100% 100%',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <CottageIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Busca tu folio
            </Typography>
            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              <TextField
                autoComplete="folio"
                autoFocus
                fullWidth
                id="folio"
                label="folio"
                margin="normal"
                name="folio"
                value={formik.values.folio}
                onChange={formik.handleChange}
                error={formik.touched.folio && Boolean(formik.errors.folio)}
                helperText={formik.touched.folio && formik.errors.folio}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {
                  status === 'checking'
                  ?
                  <CircularProgress size={24} color="inherit" />
                  :
                  'Buscar'
                }
                
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}