import { useDispatch, useSelector } from 'react-redux';
import  garantirApi  from '../api/garantirApi';
import { storeGarantir, getCatalogo,setGarantias, isLoading, onLogout } from '../store/data/garantirSlice';
// swal


export const useGarantirStore = () => {

    const { garantias, catalago, Loading } = useSelector( state => state.garantir );
    const dispatch = useDispatch();

    const getCatalogoData = async() => {
        dispatch( isLoading() );
        try {

            const { data } = await garantirApi.get('/get_cat');

            dispatch( getCatalogo(data) );

        } catch (error) {
           console.log(error);
        }

        dispatch( isLoading() );

    }


    const guardarGarantia = async( garantiaForm ) => {
        dispatch( isLoading() );
        try {


            const { data } = await garantirApi.post('/new', garantiaForm );

            dispatch( storeGarantir(data) );

        } catch (error) {
            alert(error.response.data.msg);
            alert('Intente nuevamente');
            console.log(error);
        }
        dispatch( isLoading() );
    }

    const getGarantiasData = async() => {
        dispatch( isLoading() );
        try {

            const { data } = await garantirApi.get('/get_garantias');
            dispatch( setGarantias(data) );

        }
        catch (error) {
            console.log(error);
        }

        dispatch( isLoading() );
    }





    return {
        //* Propiedades
        garantias,
        catalago, 
        isLoading, 

        //* Métodos
        getCatalogoData,
        guardarGarantia,
        setGarantias,
    }

}