import React, { useEffect } from 'react'


import { BrowserRouter } from "react-router-dom";

import { ProtectedRoutes } from './ProtectedRoutes';
import { PublicRoutes } from './PublicRoutes';
import { useAuthStore } from '../hooks/useAuthStore';


export const AppRouter = () => {

  const { status, checkAuthToken } = useAuthStore();

  useEffect(() => {
    checkAuthToken();
  }, [])

  return (
    <BrowserRouter>
      {
        status === 'authenticated'
         ? (
          <ProtectedRoutes/>
        ): (
          <PublicRoutes/>
        )

      }

    </BrowserRouter>
  )
}
