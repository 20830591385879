import { useDispatch, useSelector } from 'react-redux';
import  inmuebleApi  from '../api/InmuebleApi';
import { clearErrorMessage, onChecking, onLogin, onLogout } from '../store/auth/authSlice';


export const useAuthStore = () => {

    const { status,foto, user, errorMessage } = useSelector( state => state.auth );
    const dispatch = useDispatch();

    const startLogin = async(folio) => {
        dispatch( onChecking() );
        try {
            const { data } = await inmuebleApi.post('/',{ folio });

            localStorage.setItem('token', data.token );
            localStorage.setItem('token-init-date', new Date().getTime() );
            dispatch( onLogin({ folio: data.folio, uid: data.id ,foto: data.foto}) );
            
        } catch (error) {
            dispatch( onLogout('Credenciales incorrectas') );
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 10);
        }
    }

    const checkAuthToken = async() => {
        const token = localStorage.getItem('token');
        if ( !token ) return dispatch( onLogout() );

        try {
          
            const { data } = await inmuebleApi.get('/renew');
            localStorage.setItem('token', data.token );
            localStorage.setItem('token-init-date', new Date().getTime() );
            dispatch( onLogin({ folio: data.folio, uid: data.id ,foto: data.foto }) );

        } catch (error) {
            localStorage.clear();
            dispatch( onLogout() );
        }
    }

    const startLogout = () => {
      
        localStorage.clear();
        dispatch( onLogout() );
    }



    return {
        //* Propiedades
        errorMessage,
        status, 
        user, 
        foto,

        //* Métodos
        checkAuthToken,
        startLogin,
        startLogout,
    }

}