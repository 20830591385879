import { Grid, Paper } from '@mui/material';
import React from 'react'
import MainFeaturedPost from '../components/home/MainPost'
import TablaActividad from '../components/home/TablaActividad';

import { useInmuebleStore } from '../hooks/useInmuebleStore';
import { useAuthStore } from '../hooks/useAuthStore';

import { useEffect } from 'react';

export const HomePage = () => {
  const { foto } = useAuthStore();

  const [post] = React.useState({
    title: 'Bienvenido a la plataforma de gestión de inmuebles',
    description: 'En esta plataforma podrás gestionar tus garantias.',
    image: foto ,
    imageText: 'main image description',
    linkText: 'Continue reading…',
  });

  const { garantias, errorMessage, getDataInmueble } = useInmuebleStore();


  
  useEffect(() => {
    getDataInmueble();
  }, []);
  

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <MainFeaturedPost post={post} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ mt:2, p: 2, display: 'flex', flexDirection: 'column' }}>
          <TablaActividad garantias={garantias} />
        </Paper>
      </Grid>
    </React.Fragment>
  )
}
