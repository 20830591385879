import axios from "axios";
import { getEnvVariables } from "../helpers/getEnvVariables";

const {API_URL} = getEnvVariables();

 const garantirApi = axios.create({
  baseURL: API_URL+"garantir",
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

garantirApi.interceptors.request.use( async config => {
  const token = localStorage.getItem('token');
  if (token) {
    // is file
    config.headers = {
      ...config.headers,
      'x-token': token,
    }
  }

  return config;
});

export default garantirApi;