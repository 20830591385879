import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Check from '@mui/icons-material/Check'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

import AvaluoIcon from '../../assets/Icon/AvaluoIcon'
import CreditoIcon from '../../assets/Icon/CreditoIcon'
import DetonarIcon from '../../assets/Icon/DetonarIcon'
import EntregaIcon from '../../assets/Icon/EntregaIcon'
import FirmaIcon from '../../assets/Icon/FirmaIcon'
import InstruccionNotarialICon from '../../assets/Icon/InstruccionNotarialICon'
import IntegracionIcon from '../../assets/Icon/IntegracionIcon'
import PlanPagoIco from '../../assets/Icon/PlanPagoIco'
import VendidaIco from '../../assets/Icon/VendidaIco'
import { useMediaQuery } from '@mui/material'
import { Box } from '@mui/system';

import { useInmuebleStore } from '../../hooks/useInmuebleStore';


const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4'
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  }
}))

function QontoStepIcon (props) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed
        ? (
          <Check className='QontoStepIcon-completedIcon' />
          )
        : (
          <div className='QontoStepIcon-circle' />
          )}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  })
}))

function ColorlibStepIcon (props) {
  const { active, completed, className } = props
  
  const icons = {
    1: <IntegracionIcon width='2rem' height='2rem' />,
    2: <AvaluoIcon width='2.5rem' height='2.5rem' />,
    3: <CreditoIcon width='2.5rem' height='2.5rem' />,
    4: <InstruccionNotarialICon width='2rem' height='2rem' />,
    5: <PlanPagoIco width='2rem' height='2rem' />,
    6: <FirmaIcon width='2rem' height='2rem' />,
    7: <DetonarIcon width='2.5rem' height='2.5rem' />,
    8: <VendidaIco width='2rem' height='2rem' />,
    9: <EntregaIcon width='2rem' height='2rem' />
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
}

const steps = [
  'Integración',
  'Avaluó',
  'Tram. Crédito',
  'Inst. Notarial',
  'Plan de Pago',
  'Firma',
  'Detonación',
  'Vendida',
  'Entrega'
]

export default function StepperCard () {
  const matches = useMediaQuery('(min-width:768px)');


  const { estatus } = useInmuebleStore();


  return (
    <>
      {
        matches
          ? (
            <Stack sx={{ width: '100%' }} spacing={2} orientation='vertical'>
              <Stepper alternativeLabel activeStep={estatus} connector={<ColorlibConnector />}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
            )
          : (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Stepper activeStep={estatus} orientation='vertical'>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            )

      }
    </>
  )
}
