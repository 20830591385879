import { createSlice } from '@reduxjs/toolkit'

export const inmuebleSlice = createSlice({
  name: 'inmueble',
  initialState:{
    estatus: 0,
    fotos: [],
    avance: 0,
    message: {
      type: '',
      text: ''
    },
    Loading: false
  },
  reducers: {
    setEstatus: (state, action) => {
      // console.log(action.payload);
      state.estatus = action.payload.estatus;
      state.fotos = action.payload.fotos;
      state.avance = (action.payload.avance)*100;
    },
    setErrorMessage: (state, action) => {
      state.message = action.payload;
    },
    clearErrorMessage: (state) => {
      state.message = {
        type: '',
        text: ''
      }
    },
    isLoading: (state) => {
      state.Loading = !state.Loading
    },
    onLogout: (state) => {
      state.estatus = '';
      state.Loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setEstatus, isLoading, onLogout,clearErrorMessage} = inmuebleSlice.actions