
import * as React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useSelector } from 'react-redux';


// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { useGarantirStore } from '../hooks/useGarantirStore';
import { useNavigate } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';



// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const regexOnlyChar = /^[a-zA-Z]{2}[a-zA-Z\s]*$/;
const regexOnlyNumber = /^[0-9]{10}$/;
const regexCharAndNumbers = /^[a-zA-Z0-9\s]*$/;

const validationSchema = yup.object({
  nombre: yup
    .string('Ingresa tu nombre')
    .matches(regexOnlyChar, 'Solo se permiten letras')
    .required('El nombre es requerido'),
  apellido: yup
    .string('Ingresa tu apellido')
    .matches(regexOnlyChar, 'Solo se permiten letras')
    .required('El apellido es requerido'),
  email: yup
    .string('Ingresa tu email')
    .email('Ingresa un email valido')
    .required('El email es requerido'),
  telefono: yup
    .string('Ingresa tu telefono')
    .matches(regexOnlyNumber, 'El telefono debe tener 10 digitos')
    .required('El telefono es requerido'),
  tipo_garantia: yup
    .array()
    .min(1, 'Selecciona al menos una opcion')
    .required('El nombre es requerido'),
  descripcion: yup
    .string('Ingresa la descripcion')
    .matches(regexCharAndNumbers, 'Solo se permiten letras y numeros'),
}).shape({
  file: yup.mixed().required('A file is required'),
});

// multi select 
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// fin multi select

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function GarantIRPage() {
  const { guardarGarantia } = useGarantirStore();
  const navigate = useNavigate();

  const { catalago } = useSelector(state => state.garantir);
  const { getCatalogoData } = useGarantirStore();

  const theme = useTheme();

  React.useEffect(() => {
    getCatalogoData();
  }, []);
  const formik = useFormik({
    initialValues:{
      nombre: '',
      apellido: '',
      email: '',
      telefono: '',
      descripcion: '',
      tipo_garantia: [],
    },
    
    validationSchema: validationSchema,
    onSubmit: (values) => {
      guardarGarantia( values );
      navigate("/");
    },
  });

  const getTipoGarantia = (id) => {
    const tipoGarantia = catalago.filter((item) => item.id === id);
    return tipoGarantia[0];
  }

  return (
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center">
          Levanta Tu GarantIR
        </Typography>
        <React.Fragment>
          <Grid container spacing={3} component="form" noValidate onSubmit={formik.handleSubmit}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                fullWidth
                helperText={formik.touched.nombre && formik.errors.nombre}
                id="nombre"
                label="Nombre"
                name="nombre"
                onChange={formik.handleChange}
                required
                value={formik.values.nombre}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="apellido"
                name="apellido"
                label="Apellido"
                fullWidth
                autoComplete="family-name"
                variant="standard"
                error={formik.touched.apellido && Boolean(formik.errors.apellido)}
                helperText={formik.touched.apellido && formik.errors.apellido}
                onChange={formik.handleChange}
                value={formik.values.apellido}

              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
              id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="email"
                variant="standard"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="telefono"
                name="telefono"
                label="Telefono"
                fullWidth
                autoComplete="cellphone"
                variant="standard"
                error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                helperText={formik.touched.telefono && formik.errors.telefono}
                onChange={formik.handleChange}
                value={formik.values.telefono}
              />
            </Grid>
            {/* <Grid item xs={12} md={12}> */}
            {/* <FormControl fullWidth error={formik.touched.tipo_garantia && Boolean(formik.errors.tipo_garantia)}>
              <InputLabel id="demo-simple-select-label">Garantia</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="tipo_garantia"
                label="                                "
                
              >Paper

                {
                  catalago.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                  ))
                }
              </Select>
              {
                formik.touched.tipo_garantia && formik.errors.tipo_garantia ? (
                  <FormHelperText>{formik.touched.tipo_garantia && formik.errors.tipo_garantia}</FormHelperText>
                ) : null
              }
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Garantía</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  // value={personName}
                  // onChange={handleChange}
                  name="tipo_garantia"
                  onChange={formik.handleChange}
                  value={formik.values.tipo_garantia}
                  input={<OutlinedInput id="select-multiple-chip" label="Garantía" />}
                  renderValue={(selected) => (

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={getTipoGarantia(value).id} label={getTipoGarantia(value).nombre} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {
                    catalago.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        style={getStyles(item.nombre, item.nombre, theme)}
                      >
                        {item.nombre}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-static"
                label="Descripcion"
                multiline
                rows={4}
                variant="standard"
                name="descripcion"
                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                helperText={formik.touched.descripcion && formik.errors.descripcion}
                onChange={formik.handleChange}
                value={formik.values.descripcion}
              />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
            <InputLabel id="demo-simple-select-label">Fotos</InputLabel>
              <FilePond
                // files={formik.values.file}
                onupdatefiles={(fileItems) => {
                  formik.setFieldValue("file", fileItems.map((fileItem) => fileItem.file));
                }}
                allowMultiple={true}
                allowFileEncode={true}
                maxFiles={3}
                maxFileSize="3MB"
                name="file"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
              <FormHelperText>{formik.touched.file && formik.errors.file}</FormHelperText>



            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 3, ml: 1 }}
              >
                Enviar Garantia
              </Button>
            </Box>
          </Grid>
        </React.Fragment>
      </Paper>
  );
}