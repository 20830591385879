import { useDispatch, useSelector } from 'react-redux';
import  inmuebleApi  from '../api/InmuebleApi';
import { setEstatus, isLoading,clearErrorMessage } from '../store/data/inmuebleSlice';
import { setGarantias } from '../store/data/garantirSlice';


export const useInmuebleStore = () => {

    const state_inmueble = useSelector( state => state.inmueble );
    // console.log(state_inmueble);
    const { estatus,fotos,avance, errorMessage } = state_inmueble;

    const { garantias } = useSelector( state => state.garantir );


    const dispatch = useDispatch();

    const getDataInmueble = async() => {
        dispatch( isLoading() );
        try {
            const { data } = await inmuebleApi.get('/get_data_inmueble');
            let data_obra = {
                estatus: data.estatus,
                fotos: data.fotos_avance,
                avance: data.avance,
            }

            dispatch( setEstatus(data_obra) );
            dispatch( setGarantias(data.garantias) );
            
        } catch (error) {
        
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 10);
        }
        dispatch( isLoading() );


    }




    return {
        //* Propiedades
        estatus,
        fotos,
        avance,
        garantias,
        errorMessage,

        //* Métodos
        getDataInmueble,
    }

}