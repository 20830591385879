import { createSlice } from '@reduxjs/toolkit'

export const garantirSlice = createSlice({
  name: 'garantir',
  initialState:{
    garantias: [],
    catalago: [],
    message: {
      type: '',
      text: ''
    },
    Loading: false
  },
  reducers: {
    storeGarantir: (state, action) => {
      if( action.payload.ok === false ){
        state.message = {
          type: 'error',
          text: 'No se pueden guardar mas de 4 garantías'
        }
        return;
      }
      // state.garantias = [...state.garantias, action.payload.data];
    },
    setGarantias: (state, action) => {
      state.garantias = action.payload;
    },
    getCatalogo: (state, action) => {
      state.catalago = action.payload.data;
    },
    isLoading: (state) => {
      state.Loading = !state.Loading
    },
    onLogout: (state) => {
      state.garantias = [];
      state.Loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { storeGarantir, setGarantias, getCatalogo, isLoading, onLogout } = garantirSlice.actions