import axios from "axios";

import { getEnvVariables } from "../helpers/getEnvVariables";

const {API_URL} = getEnvVariables();

export const inmuebleApi = axios.create({
  baseURL: API_URL+"inmueble",
});


inmuebleApi.interceptors.request.use( async config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers = {
      ...config.headers,
      'x-token': token,
    }
  }
  return config;
});


export default inmuebleApi;