import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Title from './Title';
import { Typography } from '@mui/material';




function preventDefault(event) {
  event.preventDefault();
}

export default function TablaActividad({garantias}) {

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
       Actividades Recientes
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Solicito</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Telefono</TableCell>
            <TableCell>Garantia Solicitada</TableCell>
            <TableCell>Descripcion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {garantias.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.nombre} {row.apellido} </TableCell>
              <TableCell>{row.estatus}</TableCell>
              <TableCell>{row.telefono}</TableCell>
              <TableCell>{row.garantia_desc}</TableCell>
              <TableCell>{row.descripcion}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link>
    </React.Fragment>
  );
}