import { Grid, Paper, Typography } from '@mui/material'

import React from 'react'
import SliderCard from '../components/estatus_folio/SliderCard'
import StepperCard from '../components/estatus_folio/StepperCard'

import  AvanceChart  from '../components/estatus_folio/AvanceChart'

export const EstatusFolioPage = () => {
  return (
    <Grid container  spacing={2}>
      <Grid item xs={12}>
          <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column' }}>
            <StepperCard />
          </Paper>
      </Grid>
      <Grid item xs={4}>
          <Paper sx={{ mt:2, p: 4, display: 'flex', flexDirection: 'column' }}>
            <SliderCard />
          </Paper>
      </Grid>
      <Grid item xs={8}>
          <Paper sx={{ mt:2, p: 4, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Avance de obra de tu hogar
            </Typography>
            <AvanceChart />
          </Paper>
      </Grid>
    </Grid>
  )
}
