

export const getEnvVariables = () => {

  const  API_URL  = {
    API_URL: process.env.REACT_APP_API_URL,
  }

  return {
    ...API_URL,
  };

}