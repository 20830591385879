import React from 'react'
import { Route, Routes } from 'react-router-dom'
import  ConsultaPage  from '../pages/ConsultaPage'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/consulta" element={<ConsultaPage/>} />
      <Route path="/*" element={<ConsultaPage/> } />
    </Routes>
  )
}
