import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth/authSlice';
import { garantirSlice } from './data/garantirSlice';
import	{	inmuebleSlice	}	from	'./data/inmuebleSlice';


export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    garantir: garantirSlice.reducer,
    inmueble: inmuebleSlice.reducer
  },
})